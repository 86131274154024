<template>
  <nav
    v-show="isShown"
    ref="globalNavRef"
    class="prevent-select fixed bottom-0 flex h-globalNavHeight w-full max-w-screen-sm"
    aria-labelledby="global-navigation"
  >
    <div
      class="w-1/4 text-center"
      :class="[currentTab === 'home' ? colors.active : colors.inactive]"
    >
      <VBtn size="sm" variants="flat" to="/" :disabled="currentTab === 'home'">
        <div :style="currentTab === 'home' ? styles.active : ''">
          <BaseIcon size="32" icon-color="cWhite" viewbox="0 0 32 32"
            ><IconHome
          /></BaseIcon>
          <span class="block text-xs font-bold">ホーム</span>
        </div>
      </VBtn>
    </div>

    <div
      class="w-1/4 text-center"
      :class="[currentTab === 'coupon' ? colors.active : colors.inactive]"
    >
      <VBtn
        size="sm"
        variants="flat"
        to="/coupon"
        :disabled="currentTab === 'coupon'"
      >
        <div :style="currentTab === 'coupon' ? styles.active : ''">
          <BaseIcon size="32" icon-color="cWhite" viewbox="0 0 32 32"
            ><IconCoupon
          /></BaseIcon>
          <span class="block text-xs font-bold">クーポン</span>
        </div>
      </VBtn>
    </div>

    <div
      class="w-1/4 text-center"
      :class="[currentTab === 'history' ? colors.active : colors.inactive]"
    >
      <VBtn
        size="sm"
        variants="flat"
        to="/history"
        :disabled="currentTab === 'history'"
      >
        <div :style="currentTab === 'history' ? styles.active : ''">
          <BaseIcon size="32" icon-color="cWhite" viewbox="0 0 32 32"
            ><IconHistory
          /></BaseIcon>
          <span class="block text-xs font-bold">利用履歴</span>
        </div>
      </VBtn>
    </div>

    <div
      class="w-1/4 text-center"
      :class="[currentTab === 'menu' ? colors.active : colors.inactive]"
    >
      <VBtn
        size="sm"
        variants="flat"
        to="/menu"
        :disabled="currentTab === 'menu'"
      >
        <div :style="currentTab === 'menu' ? styles.active : ''">
          <BaseIcon size="32" icon-color="cWhite" viewbox="0 0 32 32"
            ><IconMenu
          /></BaseIcon>
          <span class="block text-xs font-bold">メニュー</span>
        </div>
      </VBtn>
    </div>
  </nav>
</template>

<script setup lang="ts">
  interface Props {
    isShown: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    isShown: true,
  });

  const route = useRoute();

  const currentTab = computed(() => {
    if (route.path === '/') return 'home';
    if (route.path === '/coupon') return 'coupon';
    if (route.path === '/history') return 'history';
    if (route.path === '/menu') return 'menu';
  });

  const colors = {
    active: [
      'bg-gradient-to-b',
      'from-primaryActive',
      'to-primary',
      'text-white',
    ],
    inactive: ['bg-primary', 'text-white/75'],
  };

  const styles = {
    active: 'filter: drop-shadow(0px 1px 4px rgba(255, 255, 255, 0.75))',
  };
</script>
