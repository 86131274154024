<template>
  <main class="max-w-screen-sm">
    <slot name="header" />
    <slot />

    <TheGlobalNavigation :is-shown="!isError" class="z-globalNav" />
  </main>
</template>

<script setup lang="ts">
  useHead({
    titleTemplate: (title) => {
      return title ? `${title} | ナビパーク` : 'ナビパーク';
    },
  });

  const error = useError();
  const { getQueryAsNumber } = usePathParams();

  // エラーページの場合はグローバルナビゲーションを表示しない
  const isError = computed(() => {
    return !!error.value || !!getQueryAsNumber('errorCode');
  });
</script>
